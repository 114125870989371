<template>
  <div class="app-container flex v">
    <div class="head-container">
      <!-- <quick-select ref="shops" v-model="query.shopId" filterable url="api/shop" placeholder="选择门店" display-field="shopName" class="filter-item" clearable @change="toQuery" @inited="init" style="width: 320px;" /> -->

      <!-- <el-select v-model="query.enabled" filterable clearable placeholder="状态" class="filter-item" style="width: 90px" @change="toQuery">
        <el-option :value="true" label="激活" />
        <el-option :value="false" label="禁用" />
      </el-select> -->
      <el-input v-model="query.entName" clearable placeholder="所属企业搜索" style="width: 200px;" class="filter-item" @keyup.enter.native="toQuery" />
      <el-input v-model="query.blurry" clearable placeholder="输入名称搜索" style="width: 200px;" class="filter-item" @keyup.enter.native="toQuery" />
      <el-button class="filter-item" size="mini" type="success" icon="el-icon-search" @click="toQuery">搜索</el-button>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-download" :loading="downloadLoading" @click="toDownload">导出</el-button>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-refresh-left" @click="toQuery">刷新</el-button>
    </div>

    <el-table v-loading="loading" row-key="id" :data="data" size="small" style="width: 100%;" height="100px">
      <el-table-column prop="username" label="用户名" width="150" />
      <el-table-column prop="userRealName" label="姓名" width="150" />
      <el-table-column prop="phone" label="电话" width="160" />
      <el-table-column prop="entName" label="所属企业" min-width="150" show-overflow-tooltip/>
      <el-table-column prop="shopName" label="所属门店" min-width="200" />
      <el-table-column label="状态" align="center" width="80">
        <template slot-scope="scope">
          <el-tag type="success" v-if="scope.row.enabled">激活</el-tag>
          <el-tag type="info" v-else>禁用</el-tag>
        </template>
      </el-table-column> 
      <el-table-column prop="createTime" label="创建日期" width="160">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.createTime) }}</span>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />
  </div>
</template>
<script>
import checkPermission from "@/utils/permission";
import initData from "@/mixins/initData";
import { parseTime, downloadFile } from "@/utils/index";
import { download } from "@/api/data";

export default {
  name: "User",
  mixins: [initData],
  data() {
    return {
      isAdd: false,
      delLoading: false,
      loading: false,
      deptName: "",
      shops: [],
      entType: null,
      shopId: null,
      downloadLoading: false,
      query: {
        shopId: null,
        enabled: null,
        blurry: null,
      },
    };
  },
  mounted: function () {
    this.init();
  },
  methods: {
    parseTime,
    checkPermission,
    beforeInit() {
        this.url = "api/shop/users/group";
        this.params =Object.assign(this.query, {
          sort: "entName,asc",
        });
        return true;
      // }
    },
    toDownload() {
      if (this.data && this.data.length === 0) {
        this.$message.info("当前无数据导出");
        return;
      }
      this.downloadLoading = true;
      download("api/shop/users/group/download", this.params)
        .then((result) => {
          downloadFile(result, "销售人员", "xlsx");
          this.downloadLoading = false;
        })
        .catch((err) => {
          this.$message.error(err.message || "操作失败，请稍候再试。");
          this.downloadLoading = false;
        });
    },
  },
};
</script>